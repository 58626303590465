.Hero-Content {
  animation: dropDown 1s ease forwards;
  position: absolute;
  width: 60%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

@keyframes dropDown {
  0% {
    transform: translateY(-100px) translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
  }
}
/* Quote card ANIMATION */
.Quote-Card {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.5s linear, transform 0.5s linear;
}

.Quote-Card.animate {
  opacity: 1;
  transform: scale(1);
}

/* Projects ANIMATION */
.Projects {
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.5s linear, transform 0.5s linear;
}

.Projects.animate {
  opacity: 1;
  transform: scale(1);
}

/* Projects Heading + text ANIMATION */

.Projects-Heading {
  opacity: 0;
}
.Projects-Heading.animate {
  animation: slideup 0.5s ease-out forwards;
}
@keyframes slideup {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
